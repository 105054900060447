import { Locale } from "@app/types/locale"
import Script from "next/script"

type Environment = "production" | "non-production"

interface Config {
  script: string
  noscript: string
  id: string
}

const scriptMap: Record<Environment, Record<Locale, Config>> = {
  production: {
    en: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=bzqmsLqv57VY7Yy-yrnXzQ&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}')`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`,
      id: `gtm-it-live`,
    },
    it: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=bzqmsLqv57VY7Yy-yrnXzQ&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}')`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`,
      id: `gtm-it-live`,
    },
    es: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=cCtoiXQA9bYuoPMACNPXgg&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_SPAIN}');`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_SPAIN}&gtm_auth=cCtoiXQA9bYuoPMACNPXgg&gtm_preview=env-2&gtm_cookies_win=x`,
      id: "gtm-es-live",
    },
    pt: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=xLopPczgGWYksIQ46hkiTg&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_PORTUGAL}');`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_PORTUGAL}&gtm_auth=xLopPczgGWYksIQ46hkiTg&gtm_preview=env-1&gtm_cookies_win=x`,
      id: "gtm-pt-live",
    },
    fr: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=um6rrYGSNopJLGlJ7rfEuw&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_FRANCE}');`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_FRANCE}&gtm_auth=um6rrYGSNopJLGlJ7rfEuw&gtm_preview=env-1&gtm_cookies_win=x `,
      id: "gtm-fr-live",
    },
  },
  "non-production": {
    en: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=p9bWTzDaCHaNeeqtk9ZOyQ&gtm_preview=env-84&gtm_cookies_win=x';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}')`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}&gtm_auth=p9bWTzDaCHaNeeqtk9ZOyQ&gtm_preview=env-84&gtm_cookies_win=x`,
      id: "gtm-it-stage",
    },
    it: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=p9bWTzDaCHaNeeqtk9ZOyQ&gtm_preview=env-84&gtm_cookies_win=x';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}')`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}&gtm_auth=p9bWTzDaCHaNeeqtk9ZOyQ&gtm_preview=env-84&gtm_cookies_win=x`,
      id: "gtm-it-stage",
    },
    es: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=JWx_1XUaEWMkd2YT7kRtKA&gtm_preview=env-6&gtm_cookies_win=x';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_SPAIN}')`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_SPAIN}&gtm_auth=JWx_1XUaEWMkd2YT7kRtKA&gtm_preview=env-6&gtm_cookies_win=x`,
      id: "gtm-es-stage",
    },
    pt: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=nlSyPmkUgFtaTc8cGVNz3Q&gtm_preview=env-97&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_PORTUGAL}');`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_PORTUGAL}&gtm_auth=nlSyPmkUgFtaTc8cGVNz3Q&gtm_preview=env-97&gtm_cookies_win=x`,
      id: "gtm-pt-stage",
    },
    fr: {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=uWT0Ka3fvtfiatMVSI4dLQ&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_FRANCE}');`,
      noscript: `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_FRANCE}&gtm_auth=uWT0Ka3fvtfiatMVSI4dLQ&gtm_preview=env-3&gtm_cookies_win=x`,
      id: "gtm-fr-stage",
    },
  },
}

function getConfig(lang: Locale): Config {
  const environment: Environment = process.env.NEXT_PUBLIC_APP_ENV === "production" ? "production" : "non-production"

  return scriptMap[environment][lang]
}

type Props = {
  lang: Locale
}

/**
 *
 * To be used in _document
 * source: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */
export const GoogleTagManagerNoScript: React.FC<Props> = ({ lang }) => {
  const config = getConfig(lang)

  if (!config) return null

  return (
    <noscript>
      <iframe
        height="0"
        src={config.noscript}
        style={{ display: "none", visibility: "hidden" }}
        title={config.id}
        width="0"
      ></iframe>
    </noscript>
  )
}

/**
 *
 * To be used in _app
 * source: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */
export const GoogleTagManagerScript: React.FC<Props> = ({ lang }) => {
  const config = getConfig(lang)

  if (!config) return null

  return <Script strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: config.script }} id={config.id} />
}
