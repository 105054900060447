import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { isDevOrStaging } from "../envUtils"
import { container } from "../ioc/context"
import { TENANTS } from "../tenants/constants"
import { getTenant } from "../tenants/getTenant"
import { hasGivenMeasurementTrackingConsent } from "./consent"
import { STSEvents, TypedEvents } from "./sts-events"
import { getValuationSessionId } from "./tracking"

export type JsonValue = string | number | boolean | null | JsonValue[] | { [key: string]: JsonValue }
export type JsonObject = Record<string, JsonValue>

export const buildTrackServerEvent =
  <TEvents extends TypedEvents = STSEvents>({
    hasGivenCookiesPermissions = hasGivenMeasurementTrackingConsent,
    saveSentEvent = saveSentEventInSessionStorage,
    hasAlreadySentEvent = hasAlreadySentEventFromSessionStorage,
    postServerEvent = postServerEventImpl,
  }: {
    hasGivenCookiesPermissions?: () => boolean
    saveSentEvent?: (eventName: string) => void
    hasAlreadySentEvent?: (eventName: string) => boolean
    postServerEvent?: (payload: JsonObject) => void
  }) =>
  <TEventName extends keyof TEvents>(eventName: TEventName, payload: TEvents[TEventName]) => {
    const { data, sensitiveData, uniquenessKey = "" } = payload || {}
    const tenant = getTenant()
    if (tenant !== TENANTS.casavo) {
      return
    }

    /**
     * if the user has not given the tracking/cookie permission, we must:
     * - not send any tracking id/sensitive data (valuationSessionId or sellerPropertyValuationId)
     * - not send the same event twice
     */
    const eventKey = `${eventName as string}_${uniquenessKey}`
    if (!hasGivenCookiesPermissions() && hasAlreadySentEvent(eventKey)) {
      return
    }

    const requestPayload = {
      valuationSessionId: hasGivenCookiesPermissions() ? getValuationSessionId() : null,
      ...data,
      ...(hasGivenCookiesPermissions() ? sensitiveData : {}),
    }

    const requestBody = {
      id: uuidv4(),
      name: eventName as string,
      emitted_at: new Date().toISOString(),
      payload: requestPayload,
      source: "valuation-flow",
    }

    if (isDevOrStaging()) {
      const { name: _, ...rest } = requestBody
      console.info("[EVENT/SERVER]", eventName, rest)
    }

    saveSentEvent(eventKey)

    postServerEvent(requestBody)
  }

const STS_SENT_EVENTS_KEY = "STS_SENT_EVENTS"
export const clearSTSSentEvents = () => {
  sessionStorage.setItem(STS_SENT_EVENTS_KEY, "{}")
}
const saveSentEventInSessionStorage = (eventName: string) => {
  const sentEvents = JSON.parse(sessionStorage.getItem(STS_SENT_EVENTS_KEY)!)
  sessionStorage.setItem(STS_SENT_EVENTS_KEY, JSON.stringify({ ...sentEvents, [eventName]: true }))
}
const hasAlreadySentEventFromSessionStorage = (eventName: string) => {
  const sentEvents = JSON.parse(sessionStorage.getItem(STS_SENT_EVENTS_KEY) || "{}")
  return Boolean(sentEvents[eventName])
}

const postServerEventImpl = (requestBody: JsonObject) => {
  axios.post(`${process.env.NEXT_PUBLIC_SELLERS_TRACKING_SERVICE}/events`, requestBody).catch((ex) => {
    const logger = container.get("logger")
    logger.error(new Error(`error while tracking server event ${ex}`))
  })
}

export const trackServerEvent = buildTrackServerEvent({})

export type TrackServerEvent = typeof trackServerEvent

/**
 * typing for the trackServerEvent to be used in tests
 * it does not typecheck events name/payloads
 */
export type TrackServerEventTestFunction = ReturnType<typeof buildTrackServerEvent>
