import { Fragment } from "react"

type Props = {
  lang: string
}
const AntiFlicker: React.FC<Props> = (props) => {
  const containerId = props.lang === "es" ? process.env.GOOGLE_TAG_MANAGER_ID_SPAIN : process.env.GOOGLE_TAG_MANAGER_ID

  return (
    <Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
{'${containerId}':true});`,
        }}
      ></script>
    </Fragment>
  )
}

export default AntiFlicker
